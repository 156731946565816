// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flavors-js": () => import("./../../../src/pages/flavors.js" /* webpackChunkName: "component---src-pages-flavors-js" */),
  "component---src-pages-origins-js": () => import("./../../../src/pages/origins.js" /* webpackChunkName: "component---src-pages-origins-js" */),
  "component---src-pages-roasters-js": () => import("./../../../src/pages/roasters.js" /* webpackChunkName: "component---src-pages-roasters-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-flavors-js": () => import("./../../../src/templates/flavors.js" /* webpackChunkName: "component---src-templates-flavors-js" */),
  "component---src-templates-gear-js": () => import("./../../../src/templates/gear.js" /* webpackChunkName: "component---src-templates-gear-js" */),
  "component---src-templates-origins-js": () => import("./../../../src/templates/origins.js" /* webpackChunkName: "component---src-templates-origins-js" */),
  "component---src-templates-recommendations-js": () => import("./../../../src/templates/recommendations.js" /* webpackChunkName: "component---src-templates-recommendations-js" */),
  "component---src-templates-roasters-js": () => import("./../../../src/templates/roasters.js" /* webpackChunkName: "component---src-templates-roasters-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

